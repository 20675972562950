/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/react@16.8.6/umd/react.production.min.js
 * - /npm/react-dom@16.8.6/umd/react-dom.production.min.js
 * - /npm/redux@4.0.0/dist/redux.min.js
 * - /npm/react-redux@5.0.7/dist/react-redux.min.js
 * - /npm/moment@2.22.2/min/moment.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
